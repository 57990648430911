import React, { FC } from 'react'
import Layout from '../components/layouts/layout'
import desktopBackgroundImage from '../images/banner-guillaume-desktop.jpg'
import mobileBackgroundImage from '../images/banner-guillaume-mobile.jpg'
import johannieDesktopBackgroundImage from '../images/banner-johannie-desktop.jpg'
import johannieMobileBackgroundImage from '../images/banner-johannie-mobile.jpg'
import { Hero } from '../components/hero/hero'
import { Colors } from '../colors'
import { useLayoutInformation } from '../hooks/useLayoutInformation'
import { PageRow } from '../components/page-row/page-row'
import Services from '../components/blocs/services'
import * as styles from './equipe.module.scss'

const Equipe: FC = () => {
    const { isMobile, secondaryColor } = useLayoutInformation(Colors.lightausBlue)

    return (
        <Layout titleIntlId='Equipe' className={styles.equipe}>
            <Hero
                backgroundImageUri={isMobile ? mobileBackgroundImage : desktopBackgroundImage}
                message='Découvrez notre fondateur et nos partenaires'
                buttonColor={secondaryColor}
            />
            <PageRow className={styles.guillaume}>
                <h2>Guillaume Denis-Ducharme</h2>
                <div className={styles.title}>
                    <p>Président & fondateur, Conseiller en sécurité financière</p>
                    <p>Représentant de courtiers en épargne collective, inscrit auprès de Quadrus Services
                        d'investissement Ltée</p>
                </div>
                <p>
                    Bachelier en sciences comptables de l'École des sciences de la gestion de l'UQAM, il possède plus de
                    5 années d'expérience comme conseiller en sécurité financière chez d'importants groupes financiers
                    québécois.
                </p>
                <p>
                    Fier papa, il est convaincu que les valeurs familiales doivent jouer un rôle de premier plan dans le
                    conseil financier. Grand amateur de tennis, cette discipline lui a permis d’exceller dans la
                    tactique, de comprendre le respect de l’autre et de constamment identifier les occasions qui se
                    présentent.
                </p>
            </PageRow>
            <Hero
                backgroundImageUri={isMobile ? johannieMobileBackgroundImage : johannieDesktopBackgroundImage}
                message='Découvrez notre Adjointe'
                buttonColor={secondaryColor}
            />
            <PageRow className={styles.johannie}>
                <h2>Johannie Fournelle</h2>
                <div className={styles.title}>
                    <p>Adjointe</p>
                </div>
                <p>
                    Johannie est notre adjointe administrative, son rôle est très central, mais surtout très varié. Elle
                    permet le lien entre tous les employés, les clients et les partenaires de chez Lightaus.</p>
                <p>
                    Sa facilité à l'organisation lui permet de bien cibler les priorités. Que ce soit pour son implication au marketing,
                    à l'expérience client ou en administration, elle veut toujours s'assurer que nos clients aient le meilleur service possible.
            </p>
            </PageRow>
            <PageRow backgroundColor={Colors.skyBlue} className={styles.partenaires}>
                <h2>Nos partenaires</h2>
                <p>
                    En plus de nos services en conseils financiers, nous vous donnons accès à une gamme de spécialistes
                    chevronnés dans les domaines suivants : droit des affaires et droit familial, comptabilité & impôt,
                    notariat, assurance & régime de retraite collectif, assurance de dommage & commercial ainsi qu’en
                    assurance voyage.
                </p>
                <p>
                    Communiquez avec nous dès aujourd’hui et il nous fera plaisir de vous orienter vers la personne qui
                    correspond le mieux à vos besoins.
                </p>
            </PageRow>
            <PageRow className={styles.services}>
                <h2>Découvrez nos services</h2>
                <Services />
            </PageRow>
        </Layout>
    )
}

export default Equipe
